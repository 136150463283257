#benefitsCharacters {
    position: relative;
    height: 150px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: -64px;


    @include desktop {
    }

    @include tablet {
        display: none;
    }

    @include phone {
        display: none;
    }
}

@include desktop {
	.row.benefits {
		height: 350px;
		overflow: hidden;
		position: relative;
        border-top: 2px solid black;

		.benefitsInner {
			width: 20000px;
			height: 350px;
			position: absolute;
            margin-top: 20px;
		}

		.third {
			width: 280px;
			height: 350px;
			margin-right: 100px;
			@include fontsize_benefitsParagraph;
		}

		img {
			width: 80px;
		}
	}
}

@include tablet {
	.row.benefits .third {
		img {
			height: 100px;
			width: auto;
		}

		min-height: 300px;
	}
}

@include phone {
	.row.benefits .third {
		img {
			height: 80px;
			width: auto;
		}

		min-height: none;
		margin-bottom: 40px;
		@include grid-column(12, $custom-grid);
	}
}

.quarter {
    height: 20%;
}

.half {
    height: 20%;
}



