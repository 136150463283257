.story {
	@include tablet {
		clear: both;
		float: left;
	}
}

.story>div {
	@include phone {
		margin-bottom: 20px;
	}

	@include tablet {
		margin-bottom: 20px;
	}
}

.story>.third {
	@include phone {
		margin-bottom: 40px;
	}

	@include tablet {
		margin-bottom: 40px;
	}
}

.story{
    .characterImage{
        width: 500px;
        top: -320px
    }

    .yvideo{
        max-width: 50%;

        @include phone {
            max-width: 100%;
        }
    }
}

    