.header {
	@include clearfix;
	border-bottom: $standardBorder;
	background: $white;
	box-sizing: border-box;

	@include desktop {
		height: 120px;
	}

	@include tablet {
		height: 100px;
	}

	@include phone {
		height: 90px;
	}

	.logo {
		float: left;
		padding-left: 20px;

		a {
			display: block;
		}

		img {
			display: block;

			@include desktop {
				height: 60px;
				margin-top: 30px;
				margin-bottom: 30px;
			}

			@include tablet {
				height: 50px;
				margin-top: 25px;
				margin-bottom: 25px;
			}

			@include phone {
				height: 50px;
				margin-top: 20px;
				margin-bottom: 20px;
			}
		}
	}

	.headerButtons {
		float: right;
		text-align: right;
		padding-right: 20px;

		.button {

			position: relative;
			padding: 0px;

			i {
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}

			@include desktop {
				margin-top: 20px;
				width: 80px;
				height: 80px;
			}

			@include tablet {
				margin-top: 20px;
				width: 60px;
				height: 60px;
			}

			@include phone {
				margin-top: 20px;
				width: 50px;
				height: 50px;

			}
		}
	}
}

.pageContainer {
	position: fixed;
	overflow-y: auto;

	@include desktop {
		top: 120px;
		left: 100px;
		right: 100px;
		bottom: 90px;
	}

	@include tablet {
		top: 100px;
		left: 80px;
		right: 80px;
		bottom: 80px;
	}

	@include phone {
		top: 90px;
		left: 60px;
		right: 60px;
		bottom: 60px;
	}
}

.footer {
	position: fixed;
	box-sizing: border-box;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
	border-top: 2px solid black;

	p {
		margin: 0px;
		text-align: center;
		@include fontsize_footerIcon;

		@include desktop {
			padding: 0px 20px;
			padding-bottom: 20px;
			padding-top: 20px;
		}

		@include tablet {
			padding: 0px 20px;
			padding-bottom: 20px;
			padding-top: 20px;
		}

		@include phone {
			padding: 0px 10px;
			padding-bottom: 10px;
			padding-top: 10px;
		}

		i {
			margin-left: 5px;
			margin-right: 5px;

			@include phone {
				margin-left: 2px;
				margin-right: 2px;
			}
		}
	}

    .terms{
        font-size:25px;
        text-decoration:underline;
        text-align:center;
    }

	#me_logo_1,
	#me_logo_2 {
		position: absolute;
		bottom: 20px;

		@include desktop {
			width: 50px;
			height: 50px;
		}

		@include tablet {
			width: 40px;
			height: 40px;
		}

		@include phone {
			width: 40px;
			height: 40px;
			bottom: 10px;
		}

	}

	#me_logo_1 {
		left: 20px;

		@include phone {
			left: 10px;
		}
	}

	#me_logo_2 {
		right: 20px;

		@include phone {
			right: 10px;
		}
	}

	.instagram:hover {
		background-color: #E1306C;
		background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
		background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
		color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		transition: color 0.4s;
	}

	.facebook,
	.linkedin,
	.youtube {
		transition: color 0.4s;

		&:hover {
			transition: color 0.4s;
		}
	}

	.facebook:hover {
		color: #4267B2;
	}


	.linkedin:hover {
		color: #2867B2;
	}

	.youtube:hover {
		color: #FF0000;
	}
}

.prevPage,
.nextPage {

	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	padding: 0px;

	@include fontsize_footerIcon;

	i {
		display: block;
		position: absolute;
	}

	@include desktop {
		width: 80px;
		height: 80px;
	}

	@include tablet {
		width: 60px;
		height: 60px;
	}

	@include phone {
		width: 50px;
		height: 50px;
	}
}

.prevPage {
	left: 0;
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
	border-left: none;

	i {
		top: 50%;
		left: 45%;
		transform: translateY(-50%) translateX(-50%)
	}

}

.nextPage {
	right: 0;
	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;
	border-right: none;

	i {
		top: 50%;
		right: 45%;
		transform: translateY(-50%) translateX(50%)
	}

	&:hover {
		background: #1C96CA;
	}
}