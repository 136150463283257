.hero {
    @include clearfix;

    .content {
        position: relative;
        min-height: 400px;
    }

    .characters {
        text-align: right;
        margin-top: 20px;
        position: relative;
        border: 0px solid #ff6a00;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }

        #img_characters {
            height: auto;
        }

        #img_character_run_phone {
            top: 140px;
            right: 20px;
            height: 320px;
        }

        #img_character_run_tablet {
            top: 120px;
            height: 340px;
            right: 130px;
        }
    }

    .heroText {

        p {
            @include fontsize_heroText;
            margin: 60px 0px;
            text-align: center;
            border: 0px solid #000000;

            @include desktop {
                padding-right: 30px;
            }
        }
    }

    @include tablet {
        .content {
            min-height: 220px;
        }

        .characters {

            #img_characters {
                width: auto;
            }

            #img_character_run_phone {
                top: 80px;
                right: 20px;
                height: 110px;
            }

            #img_character_run_tablet {
                top: 65px;
                height: 130px;
                right: 40px;
            }
        }


        .button {
            left: 50%;
            transform: translateX(-50%);
            bottom: -200px;
        }
    }

    @include phone {
        height: auto;

        .content {
            height: 100%;
        }

        .heroText p {
            margin: 20px 0px;
        }

        .button {
            left: 50%;
            transform: translateX(-50%);
            bottom: -200px;
            width: 180px;
            height: 180px;
        }

        .characters {
            height: 220px;

            #img_characters {
                height: 220px;
            }

            #img_character_run_phone {
                top: 80px;
                right: 20px;
                height: 110px;
            }

            #img_character_run_tablet {
                top: 65px;
                height: 130px;
                right: 40px;
            }
        }
    }
}
