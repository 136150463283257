@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$custom-grid: (columns: 12, gutter: 20px);
$black: #000;
$white: #fff;
$highlightColor: #FF008C;
$backgroundGrey: #fff;

$standardBorder: 2px solid $black;

@mixin fontsize_paragraph {

	@include desktop {
		font-size: 22px;
		line-height: 30px;
	}

	@include tablet {
		font-size: 20px;
		line-height: 24px;
	}

	@include phone {
		font-size: 18px;
		line-height: 22px;
	}
}

@mixin fontsize_h1 {

	@include desktop {
		font-size: 45px;
		line-height: 50px;
	}

	@include tablet {
		font-size: 40px;
		line-height: 44px;
	}

	@include phone {
		font-size: 28px;
		line-height: 32px;
	}
}

@mixin fontsize_h2 {

	@include desktop {
		font-size: 32px;
		line-height: 36px;
	}

	@include tablet {
		font-size: 26px;
		line-height: 28px;
	}

	@include phone {
		font-size: 24px;
		line-height: 26px;
	}
}

@mixin fontsize_footerIcon {
	@include desktop {
		font-size: 50px;
		line-height: 50px;
	}

	@include tablet {
		font-size: 40px;
		line-height: 40px;
	}

	@include phone {
		font-size: 40px;
		line-height: 40px;
	}
}




@mixin fontsize_menu {

	@include desktop {
		font-size: 30px;
		line-height: 34px;
	}

	@include tablet {
		font-size: 24px;
		line-height: 28px;
	}

	@include phone {

		font-size: 16px;
		line-height: 18px;
	}
}

@mixin fontsize_benefitsParagraph {
	@include desktop {
		font-size: 26px;
		line-height: 30px;
	}

	@include tablet {
		font-size: 24px;
		line-height: 28px;
	}

	@include phone {
		font-size: 22px;
		line-height: 26px;
	}
}

@mixin fontsize_heroText {
	@include desktop {
		font-size: 45px;
		line-height: 48px;
	}

	@include tablet {
		font-size: 35px;
		line-height: 38px;
	}

	@include phone {
		font-size: 28px;
		line-height: 32px;
	}
}