.whatIsFrasil {
    
    .textbody {
        width: 50%;
    }

    .third {

        .button{
            margin-top: 80px;
        }


        @include phone {
            margin-bottom: 40px;
            @include grid-column(12, $custom-grid);
        }
    }
}
