@include desktop {
	.row.publications {
		height: 350px;
		overflow: hidden;
		position: relative;

		.publicationsInner {
			width: 20000px;
			height: 350px;
			position: absolute;
		}

		.quarter {
			width: 315px;
			height: 350px;
			min-height: 300px;

			img {
				height: 185px;
			}
		}
	}
}

@include tablet {
	.row.publications .quarter {
		min-height: 450px;
	}
}

@include phone {
	.row.publications .quarter {
		min-height: none;
		margin-bottom: 40px;
		@include grid-column(12, $custom-grid);
	}
}