.contactForm {
    .twoThirds {
        margin: 0px;
        border: 0px solid red;
        width: 48%;


        .half {
            width: calc(50% - 20px);
        }

        .full {
            width: calc(100% - 20px);
        }
    }

    .third {
        width: 42%;
        border: 0px solid #00ff21;
        margin-left:50px;
    }

    @include tablet {
        .twoThirds {
            @include grid-column(12, $custom-grid);
            width: calc(100% - 20px);
            margin: 0px;
        }

        .third {
            @include grid-column(12, $custom-grid);
        }
    }

    @include phone {
        .twoThirds {
            width: calc(100%);
            margin: 0px;

            .half {
                @include grid-column(12, $custom-grid);
            }

            .full {
                @include grid-column(12, $custom-grid);
            }
        }

        .third {
            @include grid-column(12, $custom-grid);
        }
    }
}
