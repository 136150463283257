.button {
	@include fontsize_paragraph;
	transition: 0.4s background, color;
	border: $standardBorder;
	border-bottom-width: 5px;
	padding: 10px 20px;
	display: inline-block;
	box-sizing: border-box;
	text-decoration: none;
	text-align: center;
	color: $white;
	background: $black;
	vertical-align: bottom;
    font-stretch:expanded;
    

	&:hover {
		background: #EC008C;
		color: $black;
	}

	&:active {
		background: #EC008C;
		color: $black;
		border-bottom-width: 2px;
		margin-top: 3px;
	}

	i {
		vertical-align: middle;
	}

	span {
		margin-right: 5px;
	}


	&.onBlack {
		color: $white;
		background: $black;
		border: 3px solid white;

		&:hover {
			background: $white;
			color: $black;
		}
	}
}

.button.cta {
	position: absolute;
	bottom: -150px;
	left: 72.5%;
    transform: translateX(-50%);
	width: 200px;
	height: 200px;
	padding-top: 55px;
	border-radius: 50%;
}

.button.tac {
    position: absolute;
    width: 200px;
    height: 200px;
    padding-top: 60px;
    border-radius: 50%;
    margin-top: 100px;
    margin-bottom: 20px;

    &:hover {
        background: #1C96CA;
        color: $black;
    }

    &:active {
        background: #1C96CA;
        color: $black;
    }
}