@import "../bourbon/bourbon";
@import "../neat/neat";
@import "fontawesome/fontawesome";
@import "fontawesome/regular";
@import "fontawesome/solid";
@import "fontawesome/brands";
@import "breakpoints";
@import "variables";
@import "components/components";
@import "pages/pages";



.row {
	@include grid-container;
	width: 100%;
	box-sizing: border-box;
    border:0px solid red;


	img {
		max-width: 100%;
		display: block;
	}

	&.heading {
		margin:20px;
        width:80%;
        float:left;
        border:0px solid black;
	}
}

.pageContainer p>a {
	text-decoration: underline;
}

@include desktop {

    .full {
        @include grid-column(12, $custom-grid);
    }

    .half {
        @include grid-column(6, $custom-grid);
    }

    .third {
        @include grid-column(4, $custom-grid);
    }

    .twoThirds {
        @include grid-column(8, $custom-grid);
    }

    .quarter {
        @include grid-column(3, $custom-grid);
    }
}

@include tablet {

	.full {
		@include grid-column(12, $custom-grid);
	}

	.half {
		@include grid-column(6, $custom-grid);
	}

	.third {
		@include grid-column(6, $custom-grid);
	}

	.twoThirds {
		@include grid-column(6, $custom-grid);
	}

	.quarter {
		@include grid-column(6, $custom-grid);
	}
}

@include phone {

	.full {
		@include grid-column(12, $custom-grid);
	}

	.half {
		@include grid-column(12, $custom-grid);
	}

	.third {
		@include grid-column(12, $custom-grid);
	}

	.twoThirds {
		@include grid-column(12, $custom-grid);
	}

	.quarter {
		@include grid-column(6, $custom-grid);
	}
}




html,
body {
	padding: 0;
	margin: 0;
	font-family: 'Roboto', sans-serif;
	@include fontsize_paragraph;
	background: $white;
}

body {
	padding-bottom: 100px;
}

a {
	color: $black;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

h1 {
	@include fontsize_h1;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

h2 {
	@include fontsize_h2;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

p {
	@include fontsize_paragraph;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

fieldset {
	border: none;
	padding: 0;
	margin: 0;
	margin-bottom: 20px;

	label {
		display: block;
	}

	input[type=text], [type=email],
	textarea {
		border: $standardBorder;
		padding: 6px 4px;
		width: 100%;
		font-size: 22px;
		line-height: 30px;
		box-sizing: border-box;
	}
}

.characterImage {
	margin: 0px auto;
    border:0px solid #ff6a00;
   


	@include desktop {
		max-width: 360px !important;
	}

	@include tablet {
		max-width: 360px !important;
	}

	@include phone {
		margin-bottom: 40px;
	}
}

.characterFriends {
    margin: 0px auto;
    border: 0px solid #ff6a00;
    width: 100%;


    @include desktop {
    }

    @include tablet {
    }

    @include phone {
        margin-bottom: 40px;
    }
}