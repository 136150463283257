$tablet-width: 768px;
$desktop-width: 1024px;

@mixin phone {
	@media(max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media(min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media(min-width: #{$desktop-width}) {
		@content;
	}
}

.hideBelowDesktop {
	@media(max-width: #{$desktop-width - 1px}) {
		display: none;
	}
}

.hideOnDesktop {
	@media(min-width: #{$desktop-width}) {
		display: none;
	}
}