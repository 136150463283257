#menu {
	background: $black;
	color: $white;
	position: fixed;

	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.4s;
    overflow-y: auto;

	@include desktop {
		top: 120px;
	}

	@include tablet {
		top: 100px;
	}

	@include phone {
		top: 90px;
	}

	.row {
		padding: 0;
	}

	.showMenu & {
		display: block;
		opacity: 1;
		pointer-events: all;

	}

	.quarter {
		margin-top: 20px;
		text-align: center;
		box-sizing: border-box;
		background-position: center;
		background-size: cover;
		position: relative;

		@include desktop {
			height: 24vh;
		}

		@include tablet {
			height: 18vh;
			@include grid-column(4, $custom-grid);
		}

		@include phone {
			height: 18vh;
			@include grid-column(4, $custom-grid);
		}

		span {
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		a {
			@include fontsize_menu;
			display: block;
			width: 100%;
			height: 100%;
			// padding: 50px 30px;
			box-sizing: border-box;
			background: white;
			border: 2px solid white;
			border-bottom-width: 5px;
			transition: 0.4s background, color;

			img {
				height: 7vh;
				max-width: none;
				display: inline;

				@include desktop {
					margin-bottom: 20px;
				}

				@include tablet {
					margin-bottom: 15px;
				}

				@include phone {
					margin-bottom: 10px;
				}
			}
		}

		a:hover {
			border: 2px solid white;
			border-bottom-width: 5px;
			background: #1C96CA;

			span {
				filter: invert(1);
			}
		}

		a:active {
			background: #1C96CA;
			border-bottom-width: 2px;
			margin-top: 3px;
			height: calc(100% - 3px);
		}


	}
}