.overlay {
	display: none;
	position: fixed;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.8);

	@include desktop {
		top: 120px;
	}

	@include tablet {
		top: 100px;
	}

	@include phone {
		top: 90px;
	}

	i {
		position: absolute;
		top: 20px;
		right: 20px;
		color: white;
		font-size: 50px;
	}
}

.row.social {
	h1 {
		margin-top: 0;
	}
}

.characterss {
    margin-left: auto;
    margin-right: auto;
    width: 55%;
}

#instafeed {
    margin-bottom: 40px;
    max-width: 1080px;
    width: 100%;
    margin: auto;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, 45%);
    grid-gap: 10px;
    justify-content: center;
    align-content: center;
    template: '<a href=""><img title="" src="" /></a>'
}

.instagram-media,
blockquote {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}