@charset "UTF-8";

/// Checks for a valid CSS length.
///
/// @argument {string} $value
///
/// @return {boolean}
///
/// @access private

@function _is-length($value) {
  @return type-of($value) != "null"
    and (
      str-slice($value + "", 1, 4) == "calc"
      or str-slice($value + "", 1, 3) == "var"
      or str-slice($value + "", 1, 3) == "env"
      or index(auto inherit initial 0, $value)
      or (type-of($value) == "number" and not(unitless($value)))
    );
}
